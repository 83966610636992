<template>
  <div>
    <profile-image-bar></profile-image-bar>

    <ProfileSecondCardRow
        @toggleEditSideBar="isProfileEditLocationSidebarActive = true"
    />

    <ProfileShifts/>

    <!--    Sidebar-->
    <ProfileEditConnectedLocationsSideBar
        :is-profile-edit-location-sidebar-active.sync="isProfileEditLocationSidebarActive"/>

  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import ProfileImageBar from "./includes/ProfileImageBar";
import ProfileShifts from "./includes/ProfileShifts";
import ProfileSecondCardRow from "./includes/ProfileSecondCardRow";

import ProfileEditConnectedLocationsSideBar from "./sidebars/ProfileEditConnectedLocationsSideBar";

export default {
  components: {
    ProfileEditConnectedLocationsSideBar,
    ProfileImageBar,
    ProfileShifts,
    ProfileSecondCardRow,
    VuePerfectScrollbar,
  },
  data() {
    return {
      isAddLeavesSidebarActive: false,
      isProfileEditLocationSidebarActive: false,
    }
  }
}
</script>
