<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isProfileEditLocationSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-profile-edit-location-sidebar-active', val)"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Edit Connected Locations
        </h4>


        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <label class="font-small-3 font-weight-bold pb-1">Manage locations where clinician works</label>

          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon"/>
            </b-input-group-prepend>
            <b-form-input placeholder="Search"/>
          </b-input-group>

          <!-- Todo List -->
          <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="todo-task-list-wrapper list-group scroll-area min-vh-50 mt-2"
              style="height: 70vh;border: 1px solid #E5E5E5;border-radius: 4px;"
          >
            <ul class="list-group">
              <li
                  v-for="task in tasks"
                  :key="task.id"
                  :class="{ 'completed': task.isCompleted }"
                  class="todo-item py-1 list-group-item"
              >
                <b-form-checkbox
                    :checked="task.isCompleted"
                    @click.native.stop
                >
                  <span>{{ task.title }}</span>
                </b-form-checkbox>

              </li>
            </ul>

          </vue-perfect-scrollbar>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
            >
              Save
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1"
                type="button"
                variant="outline-white"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {tasks} from './todoTO_BE_REMOVED'
import {
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BSidebar
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {alphaNum, email, required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  methods: {
    async showCreateNewFormWizard() {
      this.$emit('toggleSideBars')
    }
  },
  components: {
    BInputGroupPrepend,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isProfileEditLocationSidebarActive',
    event: 'update:is-profile-edit-location-sidebar-active'
  },
  props: {
    isProfileEditLocationSidebarActive: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      start_date: '',
      end_date: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      tasks: tasks
    }
  },
  setup(props, {emit}) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: ''
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-profile-edit-location-sidebar-active', false)
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
