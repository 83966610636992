var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "1" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex justify-content-start",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "shadow-sm font-weight-bold  align-self-baseline float-left",
                              attrs: { variant: "white" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.back()
                                }
                              }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "ChevronLeftIcon", size: "20" }
                              })
                            ],
                            1
                          ),
                          _c("b-avatar", {
                            staticClass: "mr-n1 mt-1 ",
                            staticStyle: { padding: "7px" },
                            attrs: {
                              src: require("@/assets/images/avatars/1.png"),
                              size: "110px"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "11" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "ml-5" },
                    [
                      _c("b-col", { attrs: { cols: "12", xl: "5" } }, [
                        _vm.user
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center " },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-md-flex flex-row text-center mr-1"
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "mb-0 font-weight-700 ",
                                        attrs: { id: "user_name" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.user.first_name +
                                              " " +
                                              _vm.user.last_name
                                          ) + " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "mt-1" }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "mt-1 ",
                                    staticStyle: { "padding-left": "6px" }
                                  },
                                  [
                                    _c(
                                      "b-alert",
                                      {
                                        attrs: { variant: "primary", show: "" }
                                      },
                                      [
                                        _c(
                                          "small",
                                          {
                                            staticClass:
                                              "text-dark font-weight-700 mx-1 ",
                                            attrs: { id: "job_role" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.user.job_roles[0].name
                                              ) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0 ",
                          attrs: { cols: "12", xl: "7" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "d-flex justify-content-center justify-content-xl-end",
                                  attrs: { cols: "12", md: "7" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center pr-xl-4 pr-2"
                                    },
                                    [
                                      _c(
                                        "b-avatar",
                                        {
                                          attrs: {
                                            variant: "light-primary",
                                            rounded: ""
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "ClockIcon",
                                              size: "18"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "ml-1" }, [
                                        _c(
                                          "h4",
                                          {
                                            staticClass:
                                              "mb-0 font-weight-bolder"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateHoursWorked(
                                                    _vm.user.shifts
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("small", [_vm._v("Hours Worked")])
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    " pr-3 pl-3 pl-md-0  my-2 my-md-0",
                                  attrs: { cols: "12", md: "5" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", {
                                        staticClass:
                                          " pr-3 pl-3 pl-md-0  my-md-0 ",
                                        attrs: { cols: "12", md: "10" }
                                      }),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: " pl-md-0  my-md-0",
                                          attrs: { cols: "12", md: "2" }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn-icon bg-white shadow-sm font-weight-bold",
                                              attrs: { variant: "white" },
                                              on: {
                                                click: function($event) {
                                                  _vm.isEditProfileSidebarActive = true
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "text-primary",
                                                attrs: {
                                                  icon: "EditIcon",
                                                  size: "20"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "ml-5" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-tabs",
                            [
                              _c(
                                "b-tab",
                                { attrs: { title: "Personal Info" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "alert-body" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12   mt-1 mb-1" } },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "mr-1" },
                                            [
                                              _c("feather-icon", {
                                                attrs: { icon: "PhoneIcon" }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.user.phone_number
                                                      ? _vm.user.phone_number
                                                      : "N/A"
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "mr-1" },
                                            [
                                              _c("feather-icon", {
                                                attrs: { icon: "MailIcon" }
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.user.email) +
                                                  " "
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "b-tab",
                                { attrs: { title: "Professional Info" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "alert-body" },
                                    [
                                      !_vm.user.qalifications
                                        ? _c("div", [
                                            _c(
                                              "h5",
                                              { staticClass: "ml-1 mb-1" },
                                              [
                                                _vm._v(
                                                  "No professional information has been saved"
                                                )
                                              ]
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm.user.qalifications
                                        ? _c(
                                            "b-row",
                                            { staticClass: "ml-1 mb-1" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center pr-xl-4 pr-2"
                                                },
                                                [
                                                  _c("div", [
                                                    _c("small", [
                                                      _vm._v("Qualification")
                                                    ]),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "b-row",
                                                          {
                                                            staticClass: "pl-0 "
                                                          },
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mt-1 font-weight-light",
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "h5",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 font-weight-700"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .user
                                                                            .qalifications
                                                                            .qalification
                                                                            ? _vm
                                                                                .user
                                                                                .qalifications
                                                                                .qalification
                                                                                .name
                                                                            : ""
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center pr-xl-4 pr-2"
                                                },
                                                [
                                                  _c("div", [
                                                    _c("small", [
                                                      _vm._v("Speciality")
                                                    ]),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "b-row",
                                                          {
                                                            staticClass: "pl-0 "
                                                          },
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mt-1 font-weight-light",
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "h5",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 font-weight-700"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .user
                                                                            .qalifications
                                                                            .speciality
                                                                            ? _vm
                                                                                .user
                                                                                .qalifications
                                                                                .speciality
                                                                                .name
                                                                            : ""
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center pr-xl-4 pr-2"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("small", [
                                                        _vm._v("IT System")
                                                      ]),
                                                      _c(
                                                        "b-row",
                                                        {
                                                          staticClass: "pl-0 "
                                                        },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "mt-1 font-weight-light",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-700"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.user
                                                                          .qalifications
                                                                          .it_system
                                                                          ? _vm
                                                                              .user
                                                                              .qalifications
                                                                              .it_system
                                                                              .name
                                                                          : ""
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center pr-xl-4 pr-2"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("small", [
                                                        _vm._v(
                                                          "Smart Card Number"
                                                        )
                                                      ]),
                                                      _c(
                                                        "b-row",
                                                        {
                                                          staticClass: "pl-0 "
                                                        },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "mt-1 font-weight-light",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-700"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.user
                                                                          .qalifications
                                                                          .smart_card_number
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center pr-xl-4 pr-2"
                                                },
                                                [
                                                  _c("div", [
                                                    _c("small", [
                                                      _vm._v("Skills")
                                                    ]),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "b-row",
                                                          {
                                                            staticClass: "pl-0 "
                                                          },
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mt-1 font-weight-light",
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              _vm._l(
                                                                _vm.user
                                                                  .qalifications
                                                                  .skills,
                                                                function(
                                                                  skill
                                                                ) {
                                                                  return _c(
                                                                    "b-badge",
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        variant:
                                                                          "light-info"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-dark "
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              skill
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "b-tab",
                                { attrs: { title: "Availability" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "alert-body" },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "11" } },
                                            [_c("Available")],
                                            1
                                          ),
                                          _c("b-col", {
                                            attrs: { cols: "12", md: "1" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("EditProfileSidebar", {
        ref: "AddLocationSideBar",
        attrs: {
          "is-edit-profile-sidebar-active": _vm.isEditProfileSidebarActive
        },
        on: {
          "update:isEditProfileSidebarActive": function($event) {
            _vm.isEditProfileSidebarActive = $event
          },
          "update:is-edit-profile-sidebar-active": function($event) {
            _vm.isEditProfileSidebarActive = $event
          },
          toggleSideBars: function($event) {
            return _vm.toggleSideBars()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }