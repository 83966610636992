var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "b-avatar",
                          { attrs: { rounded: "", variant: "light-primary" } },
                          [
                            _c("feather-icon", {
                              staticClass: "text-dark font-weight-700",
                              attrs: { icon: "FileIcon", size: "18" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "padding-top": "8px" }
                          },
                          [
                            _c("h4", { staticClass: " font-weight-700" }, [
                              _vm._v(" Documents ")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value: "Add",
                              expression: "'Add'",
                              modifiers: { hover: true, top: true }
                            }
                          ],
                          staticClass: "text-primary cursor-pointer",
                          attrs: { icon: "PlusIcon", size: "18" },
                          on: {
                            click: function($event) {
                              return _vm.openFileUploadModel()
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-card-body",
                    { staticClass: " pl-1 px-0 mx-0" },
                    [
                      _c(
                        "b-overlay",
                        { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                        [
                          _c(
                            "vue-perfect-scrollbar",
                            {
                              staticClass:
                                "todo-task-list-wrapper list-group scroll-area min-vh-50 ",
                              staticStyle: { "max-height": "315px" }
                            },
                            [
                              _vm.is_dummy
                                ? _c(
                                    "b-tabs",
                                    [
                                      _c(
                                        "b-tab",
                                        { attrs: { title: "Mandatory" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "alert-body" },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "list-group list-group-flush"
                                                },
                                                _vm._l(_vm.dummy_docs, function(
                                                  item,
                                                  index
                                                ) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "list-group-item font-black pr-0"
                                                    },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "9"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "b-media",
                                                                {
                                                                  attrs: {
                                                                    "no-body":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  item !== "CCT"
                                                                    ? _c(
                                                                        "b-img",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1 ",
                                                                          attrs: {
                                                                            src: require("@/assets/images/filetypes/pdf.png"),
                                                                            alt:
                                                                              "browser img",
                                                                            height:
                                                                              "30"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "b-img",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1 ",
                                                                          attrs: {
                                                                            src: require("@/assets/images/filetypes/excel.png"),
                                                                            alt:
                                                                              "browser img",
                                                                            height:
                                                                              "30"
                                                                          }
                                                                        }
                                                                      ),
                                                                  _c(
                                                                    "h6",
                                                                    {
                                                                      staticClass:
                                                                        "align-self-center my-auto font-weight-bolder"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c("br")
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "3"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "b-badge",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "light-primary"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {},
                                                                    [
                                                                      _vm._v(
                                                                        "Approved"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                0
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-tab",
                                        { attrs: { title: "Additional" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "alert-body" },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "list-group list-group-flush"
                                                },
                                                _vm._l(
                                                  _vm.dummy_docs_additional,
                                                  function(item, index) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "list-group-item font-black pr-0"
                                                      },
                                                      [
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "9"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-media",
                                                                  {
                                                                    attrs: {
                                                                      "no-body":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    item !==
                                                                    "CCT"
                                                                      ? _c(
                                                                          "b-img",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1 ",
                                                                            attrs: {
                                                                              src: require("@/assets/images/filetypes/pdf.png"),
                                                                              alt:
                                                                                "browser img",
                                                                              height:
                                                                                "30"
                                                                            }
                                                                          }
                                                                        )
                                                                      : _c(
                                                                          "b-img",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1 ",
                                                                            attrs: {
                                                                              src: require("@/assets/images/filetypes/excel.png"),
                                                                              alt:
                                                                                "browser img",
                                                                              height:
                                                                                "30"
                                                                            }
                                                                          }
                                                                        ),
                                                                    _c(
                                                                      "h6",
                                                                      {
                                                                        staticClass:
                                                                          "align-self-center my-auto font-weight-bolder"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c("br")
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-badge",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        "light-primary"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {},
                                                                      [
                                                                        _vm._v(
                                                                          "Approved"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "list-group list-group-flush"
                                      },
                                      [
                                        _vm.documentList.length === 0
                                          ? _c("div", [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "text-center mt-3"
                                                },
                                                [_vm._v("No data for Preview ")]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._l(_vm.documentList, function(
                                          item
                                        ) {
                                          return _c(
                                            "li",
                                            {
                                              key: item.id,
                                              staticClass:
                                                "list-group-item font-black pr-0"
                                            },
                                            [
                                              _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "10" } },
                                                    [
                                                      _c(
                                                        "b-media",
                                                        {
                                                          attrs: {
                                                            "no-body": ""
                                                          }
                                                        },
                                                        [
                                                          _c("b-img", {
                                                            staticClass:
                                                              "mr-1 ",
                                                            attrs: {
                                                              src: require("@/assets/images/filetypes/pdf.png"),
                                                              alt:
                                                                "browser img",
                                                              height: "30"
                                                            }
                                                          }),
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "align-self-center my-auto font-weight-bolder"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("br")
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-tooltip",
                                                              rawName:
                                                                "v-b-tooltip.hover.top",
                                                              value: "Download",
                                                              expression:
                                                                "'Download'",
                                                              modifiers: {
                                                                hover: true,
                                                                top: true
                                                              }
                                                            },
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple.400",
                                                              value:
                                                                "rgba(255, 255, 255, 0.15)",
                                                              expression:
                                                                "'rgba(255, 255, 255, 0.15)'",
                                                              modifiers: {
                                                                "400": true
                                                              }
                                                            }
                                                          ],
                                                          staticClass:
                                                            "btn-icon bg-white text-primary  ",
                                                          attrs: {
                                                            variant:
                                                              "outline-white"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.downloadFileInSalaryStaff(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon:
                                                                "DownloadIcon"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "b-avatar",
                          { attrs: { rounded: "", variant: "light-primary" } },
                          [
                            _c("feather-icon", {
                              staticClass: "text-dark font-weight-700",
                              attrs: { icon: "CoffeeIcon", size: "18" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "padding-top": "8px" }
                          },
                          [
                            _c("h4", { staticClass: " font-weight-700" }, [
                              _vm._v(
                                " Leave " +
                                  _vm._s(
                                    _vm.leaveList.length === 0
                                      ? ""
                                      : _vm.leaveList.length
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value: "Add",
                              expression: "'Add'",
                              modifiers: { hover: true, top: true }
                            }
                          ],
                          staticClass: "text-primary cursor-pointer",
                          attrs: { icon: "PlusIcon", size: "18" },
                          on: {
                            click: function($event) {
                              return _vm.requestLeave()
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-card-body",
                    { staticClass: " pl-1 px-0 mx-0" },
                    [
                      _c(
                        "b-overlay",
                        { attrs: { show: _vm.leaveLoading, rounded: "sm" } },
                        [
                          _c(
                            "vue-perfect-scrollbar",
                            {
                              staticClass:
                                "todo-task-list-wrapper list-group scroll-area min-vh-50 ",
                              staticStyle: { "max-height": "315px" }
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "list-group list-group-flush" },
                                [
                                  _vm.leaveList.length === 0
                                    ? _c("div", [
                                        _c(
                                          "h5",
                                          { staticClass: "text-center mt-3" },
                                          [_vm._v("No data for Preview ")]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._l(_vm.leaveList, function(lave) {
                                    return _c(
                                      "li",
                                      {
                                        key: lave.id,
                                        staticClass:
                                          "list-group-item font-black pr-0"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              lave.is_day
                                                ? "" +
                                                    _vm.momentFormat(
                                                      lave.start,
                                                      "YYYY-MM-DD"
                                                    ) +
                                                    " -" +
                                                    "" +
                                                    _vm.momentFormat(
                                                      lave.end,
                                                      "YYYY-MM-DD "
                                                    )
                                                : _vm.momentFormat(
                                                    lave.start,
                                                    "dddd YYYY-MM-DD"
                                                  )
                                            ) +
                                            " :" +
                                            _vm._s(
                                              lave.is_day
                                                ? "Full day"
                                                : "" +
                                                    _vm.momentFormat(
                                                      lave.start,
                                                      "HH:mm"
                                                    ) +
                                                    " " +
                                                    "" +
                                                    _vm.momentFormat(
                                                      lave.end,
                                                      "HH:mm"
                                                    )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "float-right" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top",
                                                    value: "Accept",
                                                    expression: "'Accept'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true
                                                    }
                                                  }
                                                ],
                                                staticClass:
                                                  "btn-icon bg-white text-primary ",
                                                attrs: {
                                                  size: "23",
                                                  variant: "outline-white"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.acceptLeave(
                                                      lave.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  attrs: {
                                                    icon: "CheckSquareIcon",
                                                    size: "18"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top",
                                                    value: "Reject",
                                                    expression: "'Reject'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true
                                                    }
                                                  }
                                                ],
                                                staticClass:
                                                  "btn-icon bg-white text-danger ml-1",
                                                attrs: {
                                                  size: "23",
                                                  variant: "outline-white"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.rejectLeave(
                                                      lave.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  attrs: {
                                                    icon: "XSquareIcon",
                                                    size: "18",
                                                    variant: "outline-danger"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("br"),
                                        _c(
                                          "small",
                                          { staticClass: "text-dark" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                lave.reason === null
                                                  ? "N/A"
                                                  : lave.reason
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "b-avatar",
                          { attrs: { rounded: "", variant: "light-primary" } },
                          [
                            _c("feather-icon", {
                              staticClass: "text-dark font-weight-bolder",
                              attrs: { icon: "MapPinIcon", size: "18" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "padding-top": "8px" }
                          },
                          [
                            _c("h4", { staticClass: " font-weight-700" }, [
                              _vm._v(" Connected Locations ")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value: "Edit",
                              expression: "'Edit'",
                              modifiers: { hover: true, top: true }
                            }
                          ],
                          staticClass: "text-primary cursor-pointer",
                          attrs: { icon: "EditIcon", size: "18" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("toggleEditSideBar")
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-card-body",
                    { staticClass: " pl-1 px-0 mx-0" },
                    [
                      _c(
                        "vue-perfect-scrollbar",
                        {
                          staticClass:
                            "todo-task-list-wrapper list-group scroll-area min-vh-50",
                          staticStyle: { "max-height": "315px" }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "list-group list-group-flush" },
                            _vm._l(_vm.connectedLocations, function(location) {
                              return _c(
                                "li",
                                { staticClass: "list-group-item font-black" },
                                [
                                  _vm._v(
                                    " " + _vm._s(location.practice.name) + " "
                                  ),
                                  _c("br"),
                                  _c("small", { staticClass: "text-dark" }, [
                                    _vm._v(
                                      _vm._s(location.practice.address) +
                                        ", " +
                                        _vm._s(location.practice.city) +
                                        ", " +
                                        _vm._s(location.practice.postal_code)
                                    )
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-center",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            size: "lg",
            title: "Upload Document"
          },
          model: {
            value: _vm.showFileUploadModal,
            callback: function($$v) {
              _vm.showFileUploadModal = $$v
            },
            expression: "showFileUploadModal"
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.modalLoading, rounded: "sm" } },
            [
              _c(
                "b-row",
                { staticClass: "mx-1" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "m-auto",
                        staticStyle: { width: "100px", height: "100px" }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              "enable-background": "new 0 0 512 512"
                            },
                            attrs: {
                              id: "Layer_1",
                              version: "1.1",
                              viewBox: "0 0 512 512",
                              x: "0px",
                              "xml:space": "preserve",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              y: "0px"
                            }
                          },
                          [
                            _c("rect", {
                              staticStyle: { fill: "#32BEA6" },
                              attrs: { height: "512", width: "512", y: "0" }
                            }),
                            _c("g", [
                              _c("polygon", {
                                staticStyle: { fill: "#FFFFFF" },
                                attrs: {
                                  points:
                                    "182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808\n\t\t329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 \t"
                                }
                              }),
                              _c("polygon", {
                                staticStyle: { fill: "#FFFFFF" },
                                attrs: {
                                  points:
                                    "395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864\n\t\t379.528,405.864 379.528,301.088 395.528,301.088 \t"
                                }
                              })
                            ]),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g")
                          ]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { cols: "12" } },
                    [
                      _c("b-form-file", {
                        ref: "image_ref",
                        attrs: {
                          "drop-placeholder": "Drop file here...",
                          placeholder: "Choose a file or drop it here..."
                        },
                        model: {
                          value: _vm.uploadedFile,
                          callback: function($$v) {
                            _vm.uploadedFile = $$v
                          },
                          expression: "uploadedFile"
                        }
                      })
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "mt-5", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "float-right" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.uploadFile()
                              }
                            }
                          },
                          [
                            _c(
                              "b-overlay",
                              {
                                attrs: {
                                  show: _vm.buttonLoading,
                                  rounded: "sm"
                                }
                              },
                              [_vm._v(" Upload ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "warning" },
                            on: {
                              click: function($event) {
                                _vm.showFileUploadModal = false
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("add-leave-sidebar", {
        ref: "RequestLeaveSideBar",
        attrs: {
          "is-request-leave-sidebar-active": _vm.isRequestLeaveSidebarActive
        },
        on: {
          requestDataRefresh: _vm.requestDataRefresh,
          requestLeave: function($event) {
            return _vm.requestLeave()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }